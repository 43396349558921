<template>
  <div>
    <TablePage>
      <template #toolbar>
        <div class="topOperate">
          <el-button
            type="primary"
            @click="addTerminal"
          >
            {{ $t("common.add") }}
          </el-button>
          <el-button
            type="danger"
            :disabled="chooseRows.length===0 || isAll"
            @click="deleteSelected"
          >
            {{ $t("common.delete") }}
          </el-button>
          <el-button
            type="primary"
            :disabled="chooseRows.length===0 || isAll"
            @click="mobileGroup"
          >
            {{ $t("terminal.mobileGroup") }}
          </el-button>
          <el-button
            v-show="!isAll"
            type="primary"
            @click="selectAll"
          >
            {{ $t("common.selectAll") }}
          </el-button>
          <el-button
            v-show="isAll"
            type="primary"
            @click="selectAll"
          >
            {{ $t("common.deselectAll") }}
          </el-button>
          <el-button
            type="primary"
            style="margin-right: 10px"
            @click="searchTerminal"
          >
            {{ $t("common.find") }}
          </el-button>
          <el-select
            v-model="currentGroup"
            :popper-append-to-body="false"
            style="width:177px"
            :placeholder="$t('terminal.terminalGroupPlace')"
            clearable
          >
            <el-option
              v-for="radio in terminalGroupOptions"
              :key="radio.id"
              :value="Number(radio.id)"
              :label="radio.terminal_group_name"
            />
          </el-select>
        </div>
        <div class="btmOperate">
          <div class="usually">
            <el-button
              type="primary"
              :disabled="chooseRows.length===0"
              @click="pgAndDownload"
            >
              {{ $t("terminal.packageAndDownload") }}
            </el-button>
            <el-upload
              style="display: inline-block; margin: 0 10px 0 10px"
              :headers="{Token:token}"
              name="license_zip_file"
              :show-file-list="false"
              :action="`${url}/terminal_api/upload_licenses`"
              :on-success="successUpload"
              :file-list="importLicensesFileList"
            >
              <el-button type="primary">
                {{ $t("terminal.importLicenseBulk") }}
              </el-button>
            </el-upload>
            <el-button
              type="primary"
              :disabled="chooseRows.length===0"
              @click="allowDownloadLicense"
            >
              {{ $t("terminal.allow_download_license") }}
            </el-button>
            <el-button
              type="primary"
              :disabled="chooseRows.length===0"
              @click="notAllowDownloadLicense"
            >
              {{ $t("terminal.not_allow_download_license") }}
            </el-button>
            <el-button
              type="primary"
              @click="handleActiveDetail"
            >
              {{ $t("terminal.activeDetail") }}
            </el-button>
          <!-- <el-button @click="pgAndDownloadAll" type="primary">{{ $t("terminal.packageAndDownloadAll") }}</el-button> -->
          </div>
          <div class="hardly">
            <el-upload
              style="display: inline-block; margin: 0 10px"
              :headers="{Token:token}"
              name="package"
              :show-file-list="false"
              :action="`${url}terminal_api/terminal_package`"
              :on-success="successUpload"
              :file-list="fileList"
            >
              <el-button type="primary">
                {{ $t("terminal.uploadPackage") }}
              </el-button>
            </el-upload>
            <el-button
              type="primary"
              @click="handleUpgrade"
            >
              {{ $t("terminal.upgrade") }}
            </el-button>
          </div>
        </div>
      </template>
      <h2 class="note">{{ $t("terminal.terminalNote") }}</h2>
      <el-table
        ref="terminalListTable"
        border
        :data="terminalList"
        row-key="id"
        @select="handleSelect"
        @select-all="handleSelectAll"
      >
        <el-table-column
          :reserve-selection="true"
          type="selection"
          width="40"
        />
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form
              class="table-expand"
              inline
              label-width="140px"
            >
              <el-form-item :label="$t('terminal.name')">
                <span>{{ props.row.name }}</span>
              </el-form-item>
              <el-form-item :label="$t('terminal.type')">
                <span>{{ filterTerminalType(props.row.terminal_type) }}</span>
              </el-form-item>
              <el-form-item :label="$t('terminal.lid')">
                <span>{{ props.row.lid ? props.row.lid : "-" }}</span>
              </el-form-item>
              <el-form-item :label="$t('terminal.projectName')">
                <span>{{ props.row.project_name ? props.row.project_name : "-" }}</span>
              </el-form-item>
              <el-form-item :label="$t('terminal.status')">
                <el-tag
                  v-if="props.row.status == 0"
                  type="danger"
                >
                  {{ filterStatus(props.row.status) }}
                </el-tag>
                <el-tag
                  v-else
                  type="success"
                >
                  {{ filterStatus(props.row.status) }}
                </el-tag>
              </el-form-item>
              <el-form-item :label="$t('terminal.ip')">
                <span>{{ props.row.ip ? props.row.ip : "-" }}</span>
              </el-form-item>
              <el-form-item :label="$t('terminal.wehreGroup')">
                <span>{{ props.row.terminal_group_name ? props.row.terminal_group_name : "-" }}</span>
              </el-form-item>
              <el-form-item :label="$t('terminal.last_upload_log_time')">
                <span>{{ props.row.last_upload_log_time ? props.row.last_upload_log_time : "-" }}</span>
              </el-form-item>
              <el-form-item :label="$t('terminal.package_name')">
                <span>{{ props.row.package_name ? props.row.package_name : "-" }}</span>
              </el-form-item>
              <el-form-item :label="$t('terminal.sdk_version')">
                <span>{{ props.row.sdk_version ? props.row.sdk_version : "-" }}</span>
              </el-form-item>
              <el-form-item :label="$t('terminal.activate_filename')">
                <span>{{ props.row.activate_filename ? props.row.activate_filename : "-" }}</span>
              </el-form-item>
              <el-form-item :label="$t('terminal.authStatus')">
                <span>{{ authStatus(props.row.authorization_state) }}</span>
              </el-form-item>
              <el-form-item :label="$t('terminal.license_filename')">
                <span>{{ props.row.license_filename ? props.row.license_filename : "-" }}</span>
              </el-form-item>
              <el-form-item :label="$t('terminal.license_download_state')">
                <span>{{ downloadState(props.row.license_download_state) }}</span>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column
          width="100"
          align="center"
          prop="terminal_type"
          :label="$t('terminal.type')"
        >
          <template slot-scope="scope">
            {{ filterTerminalType(scope.row.terminal_type) }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="name"
          :label="$t('terminal.name')"
        />
        <!-- <el-table-column align="center" prop="lid" :label="$t('terminal.lid')">
          <template slot-scope="scope">
              {{scope.row.lid ? scope.row.lid : "-"}}
          </template>
        </el-table-column> -->
        <el-table-column
          align="center"
          prop="terminal_group_name"
          :label="$t('terminal.wehreGroup')"
        >
          <template slot-scope="scope">
            {{ scope.row.terminal_group_name ? scope.row.terminal_group_name : "-" }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="project_name"
          :label="$t('terminal.projectName')"
        >
          <template slot-scope="scope">
            {{ scope.row.project_name ? scope.row.project_name : "-" }}
          </template>
        </el-table-column>
        <el-table-column
          width="80"
          align="center"
          prop="msg_count"
          :label="$t('terminal.msg_count')"
        >
          <template slot-scope="scope">
            <el-tag 
              style="cursor:pointer"
              v-if="scope.row.msg_count"
              type="danger" 
              @click="enterErrorPage(scope.row.id)"> {{ scope.row.msg_count }}</el-tag>
            <template v-else> - </template>
          </template>
        </el-table-column>
        <!-- <el-table-column align="center" prop="status" :label="$t('terminal.status')" width="80">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status == 0" type="danger">{{ filterStatus(scope.row.status) }}</el-tag>
            <el-tag v-else type="success">{{ filterStatus(scope.row.status) }}</el-tag>
          </template>
        </el-table-column> -->
        <!-- <el-table-column align="center" prop="ip" :label="$t('terminal.ip')">
          <template slot-scope="scope">
              {{scope.row.ip ? scope.row.ip : "-"}}
          </template>
        </el-table-column> -->
        <el-table-column
          width="100"
          align="center"
          prop="authorization_state"
          :label="$t('terminal.authStatus')"
        >
          <template slot-scope="scope">
            {{ authStatus(scope.row.authorization_state) }}
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          align="center"
          prop="allow_download_license"
          :label="$t('terminal.allow_download_license')"
        >
          <template slot-scope="scope">
            {{ scope.row.allow_download_license ? $t('common.open') : $t('common.close')}}
          </template>
        </el-table-column>
        <!-- <el-table-column align="center" prop="last_upload_log_time" :label="$t('terminal.last_upload_log_time')" width="150">
          <template slot-scope="scope">
              {{scope.row.last_upload_log_time ? scope.row.last_upload_log_time : "-"}}
          </template>
        </el-table-column> -->
        <!-- <el-table-column align="center" prop="package_name" :label="$t('terminal.package_name')" width="150">
          <template slot-scope="scope">
              {{scope.row.package_name ? scope.row.package_name : "-"}}
          </template>
        </el-table-column> -->
        <!-- <el-table-column align="center" prop="sdk_version" :label="$t('terminal.sdk_version')" width="150">
          <template slot-scope="scope">
              {{scope.row.sdk_version ? scope.row.sdk_version : "-"}}
          </template>
        </el-table-column> -->
        <!-- <el-table-column align="center" prop="authorization_state" :label="$t('terminal.authStatus')" width="80">
          <template slot-scope="scope">
              {{ authStatus(scope.row.authorization_state) }}
          </template>
        </el-table-column> -->
        <el-table-column
          :label="$t('common.operate')"
          :width="widthByLang()"
          fixed="right"
          header-align="center"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="editTerminal(scope.row)"
            >
              {{ $t("common.edit") }}
            </el-button>
            <el-button
              size="mini"
              type="danger"
              @click="deleteTerminal(scope.row.id)"
            >
              {{ $t("common.delete") }}
            </el-button>
            <!-- <el-button
              size="mini"
              type="primary"
              @click="restartDevice(scope.row.id)"
            >{{ $t("terminal.restartDevice")}}</el-button>
            <el-button
              size="mini"
              type="primary"
              @click="restartProcess(scope.row.id)"
            >{{ $t("terminal.restartProcess")}}</el-button> -->
            <!-- <el-button
              size="mini"
              type="primary"
              v-show="scope.row.terminal_type == '0'"
              @click="uploadTerminalLog(scope.row)"
            >{{ $t("terminal.logUpload")}}</el-button> -->
            <el-button
              v-show="scope.row.terminal_type != 1"
              size="mini"
              type="primary"
              @click="pushChannel(scope.row.id)"
            >
              {{ $t("terminal.pushChannel") }}
            </el-button>
<!--
            <el-button
              v-show="scope.row.terminal_type != 1"
              size="mini"
              type="primary"
              :disabled="!scope.row.activate_filename"
              @click="downloadActive(scope.row)"
            >
              {{ $t("terminal.exportActive") }}
            </el-button>
-->
<!--
            <el-upload
              v-show="scope.row.terminal_type != 1"
              ref="dat"
              style="display:inline-block;margin-left: 10px;"
              :headers="{Token:token}"
              :data="{terminal_id: scope.row.id}"
              :show-file-list="false"
              name="license"
              accept=".dat"
              :action="`${url}/terminal_api/license`"
              :on-success="successUpload"
              :before-upload="beforeUpload"
              :file-list="datFileList"
            >
              <el-button
                size="mini"
                type="primary"
              >
                {{ $t("terminal.importLicense") }}
              </el-button>
            </el-upload>
-->
          </template>
        </el-table-column>
      </el-table>
      <template #page>
        <el-pagination
          :current-page="currentPage"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </template>
    </TablePage>
    <!-- 添加/编辑 终端弹窗 -->
    <el-dialog
      :title="$t('terminal.terminal_msg')"
      :visible.sync="terminalDialog"
      width="900px"
    >
      <el-form
        ref="terminalRef"
        :model="terminalMsg"
        inline
        label-width="180px"
        :rules="terminalMsgRules"
      >
        <el-form-item
          :label="$t('terminal.name')"
          prop="name"
        >
          <el-input
            v-model="terminalMsg.name"
            style="width:177px"
          />
        </el-form-item>
        <el-form-item
          :label="$t('terminal.type')"
          prop="terminal_type"
        >
          <el-select
            v-model.number="terminalMsg.terminal_type"
            :popper-append-to-body="false"
            style="width:177px"
          >
            <el-option
              v-for="item in terminalTypeOptions"
              :key="item.option_label"
              :label="item.option_label"
              :value="item.option_value"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('terminal.groupName')"
          prop="terminal_group_id"
        >
          <el-select
            v-model="terminalMsg.terminal_group_id"
            :popper-append-to-body="false"
            style="width:177px"
            clearable
          >
            <el-option
              v-for="radio in terminalGroupOptions"
              :key="radio.id"
              :value="Number(radio.id)"
              :label="radio.terminal_group_name"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('config.psl_verbose_log')"
          prop="psl_verbose_log"
        >
          <el-select
            v-model="terminalMsg.psl_verbose_log"
            :popper-append-to-body="false"
            style="width:177px"
            :disabled="terminalMsg.terminal_type=='1'"
            clearable
          >
            <el-option
              v-for="radio in pslVerboseLogList"
              :key="radio.option_value"
              :value="Number(radio.option_value)"
              :label="radio.option_label"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('config.ptcp_log')"
          prop="ptcp_log"
        >
          <el-select
            v-model="terminalMsg.ptcp_log"
            :popper-append-to-body="false"
            style="width:177px"
            :disabled="terminalMsg.terminal_type=='1'"
            clearable
          >
            <el-option
              v-for="radio in ptcpLogList"
              :key="radio.option_value"
              :value="Number(radio.option_value)"
              :label="radio.option_label"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('terminal.projectName')"
          prop="project_name"
        >
          <el-select
            v-model="terminalMsg.project_name"
            :popper-append-to-body="false"
            style="width:177px"
            :disabled="terminalMsg.terminal_type=='1'"
            clearable
          >
            <el-option
              v-for="item in projectOptions"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('terminal.lid')"
          prop="lid"
        >
          <el-input
            v-model="terminalMsg.lid"
            type="textarea"
            style="width:177px"
            :disabled="terminalMsg.terminal_type=='1'"
          />
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="terminalDialog = false">{{ $t("common.cancel") }}</el-button>
        <el-button
          type="primary"
          @click="confirmAddTerminal"
        >{{ $t("common.confirm") }}</el-button>
      </span>
    </el-dialog>
    <!-- 选择分组弹窗 -->
    <el-dialog
      :title="$t('terminal.mobileGroup')"
      :visible.sync="chooseGroupDialog"
      width="500px"
    >
      <el-form
        ref="chooseGroup"
        :model="chooseGroup"
        inline
        label-width="180px"
        :rules="chooseGroupRules"
      >
        <el-form-item
          :label="$t('terminal.groupName')"
          prop="terminal_group_id"
        >
          <el-select
            v-model="chooseGroup.terminal_group_id"
            :popper-append-to-body="false"
            style="width:177px"
          >
            <el-option
              v-for="radio in terminalGroupOptions"
              :key="radio.id"
              :value="Number(radio.id)"
              :label="radio.terminal_group_name"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="chooseGroupDialog = false">{{ $t("common.cancel") }}</el-button>
        <el-button
          type="primary"
          @click="confirmMobileGroup"
        >{{ $t("common.confirm") }}</el-button>
      </span>
    </el-dialog>
    <!-- 升级弹窗 -->
    <el-dialog
      :title="$t('terminal.upgrade_msg')"
      :visible.sync="upgradeDialog"
      width="500px"
    >
      <el-form
        ref="updateMsg"
        :model="updateMsg"
        inline
        label-width="180px"
      >
        <el-form-item
          :label="$t('terminal.groupName')"
          prop="terminal_group_id"
        >
          <el-select
            v-model="updateMsg.terminal_group_id"
            :popper-append-to-body="false"
            style="width:177px"
          >
            <el-option
              v-for="radio in terminalGroupOptions"
              :key="radio.id"
              :value="Number(radio.id)"
              :label="radio.terminal_group_name"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('terminal.name')"
          prop="terminal_id"
        >
          <el-select
            v-model="updateMsg.terminal_id"
            :popper-append-to-body="false"
            style="width:177px"
          >
            <el-option
              v-for="radio in pushTerminalOptions"
              :key="radio.id"
              :value="Number(radio.id)"
              :label="radio.name"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="upgradeDialog = false">{{ $t("common.cancel") }}</el-button>
        <el-button
          type="primary"
          @click="updateAll"
        >{{ $t("terminal.updateAll") }}</el-button>
        <el-button
          type="primary"
          @click="confirmupdate"
        >{{ $t("common.confirm") }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { blobDodnload } from '@/common/util.js'
import { customConfig } from '@/api/request'
import TablePage from '@/components/TablePage.vue'
import { get_all_projectName } from '@/api/project'
import { get_sename_list } from '@/api/api.js'
import { zh } from '@/api/code.js'
import {
  get_terminals,
  modify_terminal,
  add_terminal,
  delete_terminal,
  rebort,
  get_all_terminals,
  assign_terminal_group,
  terminal_update_seq,
  get_terminals_by_type,
  activate_file,
  download_activate_files,
  allow_download_license
} from '@/api/terminal'
import { mapState, mapGetters, mapMutations } from 'vuex'
export default {
  components: {
    TablePage
  },
  data () {
    return {
      isAll: false,
      is_allow: 1, // 1 下发，0 取消下发
      url: customConfig.baseURL,
      fileList: [],
      datFileList: [],
      importLicensesFileList: [],
      upgradeDialog: false,
      updateMsg: {
        terminal_id: '',
        terminal_group_id: ''
      },
      pushTerminalOptions: [],
      terminalList: [],
      currentPage: 1,
      pageSize: 10,
      total: null,
      terminalDialog: false,
      chooseGroupDialog: false,
      terminalMsg: {},
      terminalTypeOptions: [],
      chooseRows: [],
      projectOptions: [],
      chooseGroup: {
        terminal_group_id: null
      },
      chooseGroupRules: {
        terminal_group_id: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }]
      },
      terminalMsgRules: {
        name: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
        terminal_type: [
          { required: true, message: this.$t('common.required'), trigger: 'blur' }
        ]
      },
      isAdd: true,
      pslVerboseLogList: [],
      ptcpLogList: [],
      terminalGroupOptions: [],
      currentGroup: ''
    }
  },
  computed: {
    ...mapState(['terminalGroupID']),
    ...mapGetters(['token'])
  },
  created () {
    this.pageSize = Number(localStorage.terminalPageSize) || 10
    this.init()
  },
  methods: {
    ...mapMutations(['addToken', 'addSAID']),
    widthByLang () {
      return localStorage.lang == 'en' ? 920 : 300
    },
    // 跳转到错误提示页面
    enterErrorPage (id) {
      this.$router.push(`/terminal/failActive/${id}`)
    },
    filterTerminalType (value) {
      switch (value) {
        case 0:
          return this.$t('common.push')
        case 1:
          return this.$t('common.pull')
        case 2:
          return this.$t('common.pushAndPull')
        default:
          return '-'
      }
    },
    selectAll () {
      if (!this.isAll) {
        this.isAll = true
        this.$refs.terminalListTable.clearSelection()
        this.$refs.terminalListTable.toggleAllSelection()
      } else {
        this.isAll = false
        this.$refs.terminalListTable.clearSelection()
        this.chooseRows = []
      }
    },
    // 下发证书
    async allowDownloadLicense () {
      this.is_allow = 1
      let res
      const loading = this.$globalLoading()
      if (this.isAll) {
        await this.$confirm(this.$t('common.isConfirmSelectAll'), this.$t('common.remind'), {
          confirmButtonText: this.$t('common.confirm'),
          cancelButtonText: this.$t('common.cancel'),
          type: 'warning'
        }).then(async () => {
          res = await allow_download_license({ terminal_id_list: [], is_allow: this.is_allow })
        }).catch(() => {})
      } else {
        res = await allow_download_license({ terminal_id_list: this.chooseRows, is_allow: this.is_allow })
      }
      if (res.data.code === 0) {
        this.$message.success(this.$t('terminal.allow_download_license_remind'))
      }
      loading.close()
    },
    async notAllowDownloadLicense () {
      this.is_allow = 0
      let res
      const loading = this.$globalLoading()
      if (this.isAll) {
        await this.$confirm(this.$t('common.isConfirmSelectAll'), this.$t('common.remind'), {
          confirmButtonText: this.$t('common.confirm'),
          cancelButtonText: this.$t('common.cancel'),
          type: 'warning'
        }).then(async () => {
          res = await allow_download_license({ terminal_id_list: [], is_allow: this.is_allow })
        }).catch(() => {})
      } else {
        res = await allow_download_license({ terminal_id_list: this.chooseRows, is_allow: this.is_allow })
      }
      if (res.data.code === 0) {
        this.$message.success(this.$t('terminal.cancel_allow_download_license_remind'))
      }
      loading.close()
    },
    handleActiveDetail () {
      this.$router.push(`/terminal/failActive`)
    },
    handleUpgrade () {
      this.getAllTerminals()
      this.getPushTeminals()
      this.updateMsg = {
        terminal_id: '',
        terminal_group_id: ''
      }
      this.upgradeDialog = true
    },
    // 导出激活文件
    async downloadActive (row) {
      const res = await activate_file({
        activate_filename: row.activate_filename,
        terminal_name: row.name
      })
      blobDodnload(res)
    },
    // 打包下载激活文件
    async pgAndDownload () {
      let res
      const loading = this.$globalLoading()
      if (this.isAll) {
        await this.$confirm(this.$t('common.isConfirmSelectAll'), this.$t('common.remind'), {
          confirmButtonText: this.$t('common.confirm'),
          cancelButtonText: this.$t('common.cancel'),
          type: 'warning'
        }).then(async () => {
          res = await download_activate_files({ terminal_id_list: [] })
        }).catch(() => {})
      } else {
        res = await download_activate_files({
          terminal_id_list: this.chooseRows
        })
      }
      loading.close()
      blobDodnload(res)
    },
    async pgAndDownloadAll () {
      const loading = this.$globalLoading()
      const res = await download_activate_files({
        terminal_id_list: []
      })
      loading.close()
      blobDodnload(res)
    },
    // 授权状态过滤
    authStatus (value) {
      switch (value) {
        case 1:
          return this.$t('terminal.inactivated')
        case 2:
          return this.$t('terminal.certificateNotDownloaded')
        case 3:
          return this.$t('terminal.certificateDownloaded')
        case 4:
          return this.$t('terminal.certificateInvalid')
        default:
          return '-'
      }
    },
    // 下载状态过滤
    downloadState (value) {
      if (value == 0) {
        return this.$t('terminal.notDownloaded')
      } else if (value == 1) {
        return this.$t('terminal.downloaded')
      } else {
        return '-'
      }
    },
    // 全部升级
    async updateAll () {
      const res = await terminal_update_seq({
        terminal_id: '',
        terminal_group_id: ''
      })
      if (res.data.code === 0) {
        this.upgradeDialog = false
        this.$message.success(this.$t('terminal.updateRemind'))
      }
    },
    // 确认升级
    async confirmupdate () {
      const res = await terminal_update_seq(this.updateMsg)
      if (res.data.code === 0) {
        this.upgradeDialog = false
        this.$message.success(this.$t('terminal.updateRemind'))
      }
    },
    beforeUpload (file) {
      // 校验dat文件名称是否符合 iLive-xxxxx-license.dat 格式
      const reg = /^iLive-(.+)-license.dat$/
      console.log(reg.test(file.name))
      if (!reg.test(file.name)) {
        this.$message.error(this.$t('terminal.datFileNameStandard'))
        return false
      }
    },
    successUpload (res) {
      if (res.code === 1002) {
        this.addToken('')
        this.addSAID('')
        this.$router.push('/login')
        return
      }
      if (localStorage.lang == 'en') {
        if (res.code === 0) {
          this.$message.success(this.$t('terminal.uploadRemind'))
        } else {
          this.$message.error(res.msg)
        }
      } else {
        if (res.code === 0) {
          this.$message.success(this.$t('terminal.uploadRemind'))
        } else {
          this.$message.error(zh[res.code])
        }
      }
    },
    pushChannel (id) {
      this.$router.push(`/terminal/push_channel/${id}`)
    },
    // 分组查询终端
    async searchTerminal () {
      await this.getTerminalList(this.currentGroup)
      this.$message.success(this.$t('common.searchSuc'))
    },
    // 当页全选
    handleSelectAll (selection) {
      this.chooseRows = selection.map((item) => {
        return item.id
      })
    },
    // 选中
    handleSelect (selection) {
      this.chooseRows = selection.map((item) => {
        return item.id
      })
    },
    // 删除选中
    deleteSelected () {
      this.deleteTerminal(this.chooseRows)
      this.chooseRows = []
    },
    // 移动分组
    async mobileGroup () {
      this.chooseGroupDialog = true
    },
    // 获取终端分组列表
    async getAllTerminals () {
      const res = await get_all_terminals()
      if (res.data.code === 0) {
        this.terminalGroupOptions = res.data.data
      }
    },
    // 确认移动分组
    confirmMobileGroup () {
      this.$refs.chooseGroup.validate(async (valid) => {
        if (valid) {
          const res = await assign_terminal_group({
            terminal_group_id: this.chooseGroup.terminal_group_id,
            terminal_id: this.chooseRows
          })
          if (res.data.code === 0) {
            this.$message.success(this.$t('terminal.adjustGroupSunc'))
            this.getTerminalList(this.terminalGroupID)
            this.chooseGroupDialog = false
          }
        }
      })
    },
    // 重启设备
    async restartDevice (id) {
      const res = await rebort({
        terminal_id: id,
        command: 2
      })
      if (res.data.code === 0) {
        this.$message.success(this.$t('terminal.sendSucc'))
      }
    },
    // 重启进程
    async restartProcess (id) {
      const res = await rebort({
        terminal_id: id,
        command: 1
      })
      if (res.data.code === 0) {
        this.$message.success(this.$t('terminal.sendSucc'))
      }
    },
    // 删除当前终端
    deleteTerminal (id) {
      this.$confirm(this.$t('common.isConfirmDelete'), this.$t('common.remind'), {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(async () => {
        const res = await delete_terminal({ terminal_id: id })
        if (res.data.code === 0) {
          this.$message.success(this.$t('common.deleteSuccess'))
          this.getTerminalList(this.terminalGroupID)
        }
      }).catch(() => {})
    },
    // 打开添加终端弹窗
    addTerminal () {
      this.terminalDialog = true
      this.isAdd = true
      this.terminalMsg = {
        name: '',
        project_name: '',
        terminal_type: 1, // "0": 推流端 "1": 收流端
        lid: '',
        psl_verbose_log: '',
        ptcp_log: '',
        terminal_group_id: null
      }
    },
    // 打开编辑终端弹窗
    editTerminal (msg) {
      this.isAdd = false
      this.terminalDialog = true
      const { name, project_name, terminal_type, lid, id, psl_verbose_log, ptcp_log, terminal_group_id } = msg
      this.terminalMsg = { name, project_name, terminal_type, lid, terminal_id: id, psl_verbose_log, ptcp_log, terminal_group_id }
    },
    // 确认添加或修改终端
    confirmAddTerminal () {
      this.$refs.terminalRef.validate(async (valid) => {
        if (valid) {
          const loading = this.$globalLoading()
          if (this.isAdd) {
            const res = await add_terminal(this.terminalMsg)
            if (res.data.code === 0) {
              this.$message.success(this.$t('common.addSuccess'))
              this.getTerminalList(this.terminalGroupID)
              this.terminalDialog = false
            }
          } else {
            const res = await modify_terminal(this.terminalMsg)
            if (res.data.code === 0) {
              this.$message.success(this.$t('common.changeSuccess'))
              this.getTerminalList(this.terminalGroupID)
              this.terminalDialog = false
            }
          }
          loading.close()
        }
      })
    },
    // 获取所有project名称列表
    async getAllProjectName () {
      const res = await get_all_projectName()
      if (res.data.code === 0) {
        this.projectOptions = res.data.data
      }
    },
    // 在线离线过滤
    filterStatus (status) {
      return status == 0 ? this.$t('common.offline') : this.$t('common.online')
    },
    // 根据分组获取终端列表
    async getTerminalList (groupID) {
      const param = {
        page_num: this.currentPage,
        page_size: this.pageSize,
        order_by: 'id',
        sort: 1,
        query_condition: {}
      }
      // 如果存在分组，则查询分组里的终端，否则查询所有终端
      if (groupID) {
        param.query_condition = {
          terminal_group_id: {
            type: 'int',
            value: groupID
          }
        }
      }
      const res = await get_terminals(param)
      if (res.data.code === 0) {
        this.terminalList = res.data.data.terminals
        this.total = res.data.data.terminal_count
      }
    },
    // 获取所有的推流终端
    async getPushTeminals () {
      const res = await get_terminals_by_type({ terminal_type: 0 })
      if (res.data.code === 0) {
        this.pushTerminalOptions = res.data.data
      }
    },
    // 修改pageSize
    async handleSizeChange (size) {
      this.pageSize = size
      localStorage.terminalPageSize = size
      if (this.currentGroup) {
        await this.getTerminalList(this.currentGroup)
      } else {
        await this.getTerminalList(this.terminalGroupID)
      }
      if (this.isAll) {
        this.$refs.terminalListTable.clearSelection()
        this.$refs.terminalListTable.toggleAllSelection()
      }
    },
    // 修改当前页码
    async handleCurrentChange (current_page) {
      this.currentPage = current_page
      if (this.currentGroup) {
        await this.getTerminalList(this.currentGroup)
      } else {
        await this.getTerminalList(this.terminalGroupID)
      }
      if (this.isAll) {
        this.$refs.terminalListTable.clearSelection()
        this.$refs.terminalListTable.toggleAllSelection()
      }
    },
    // 获取下拉参数
    async getSelectList () {
      const getTerminalTypeOptions = get_sename_list({ select_name: 'terminal_type' })
      const getPslVerboseLogList = get_sename_list({ select_name: 'psl_verbose_log' })
      const getPtcpLogList = get_sename_list({ select_name: 'ptcp_log' })
      const res1 = await getPslVerboseLogList
      const res2 = await getPtcpLogList
      const res3 = await getTerminalTypeOptions
      this.pslVerboseLogList = res1.data.data
      this.ptcpLogList = res2.data.data
      this.terminalTypeOptions = res3.data.data
    },
    // 初始化
    init () {
      this.getAllTerminals()
      this.getPushTeminals()
      this.getAllProjectName()
      this.getTerminalList(this.terminalGroupID)
      this.getSelectList()
    }
  }
}
</script>

<style lang='scss' scoped>
  .table-expand {
    width: 1100px
  }
  .table-expand /deep/ label {
    width: 150px;
    color: #99a9bf;
  }
  .note{
      line-height: 40px;
      margin-right: 50px;
      margin-bottom: 20px;
    }
  .table-expand /deep/ .el-form-item {
    width: 520px;
  }
  .topOperate {
    margin-bottom: 10px;
  }
  .btmOperate {
    display: flex;
    justify-content: space-between;
  }
</style>
